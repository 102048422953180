import Vue from 'vue';
import uk from './i18n/uk.json';
import ru from './i18n/ru.json';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages () {
  return  {
    uk: {
      ...uk
    },
    ru: {
      ...ru
    }
  }
}

export default new VueI18n({
  locale:  'uk',
  fallbackLocale:  'ru',
  messages: loadLocaleMessages()
})

