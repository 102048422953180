

  import HeaderListItem from './shared/Header-list-item.vue'
  import HeaderLangsSwitcher from './shared/Header-langs-switcher.vue'
  import HeaderLogo from './shared/Header-logo.vue'
  import {Component, Vue} from "vue-property-decorator";

  @Component({
    name: 'header-desktop',
    components: {
      HeaderListItem,
      HeaderLangsSwitcher,
      HeaderLogo
    }
  })

  export default class HeaderDesktop extends Vue {

    isActive = true;

    onChange(event) {
      this.$i18n.locale = event;
      this.isActive = !this.isActive;
    }
  }

