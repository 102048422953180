

  import { Component, Vue } from "vue-property-decorator";
  import PhoneInputComponent from "@/shared/components/phone-input.vue";

  @Component({
    name: 'pop-up',
    components: {
      PhoneInputComponent
    }
  })

  export default class PopUpComponent extends Vue {
    isUserScrolling = false;
    isPopupOpened = false;

    created() {
      window.addEventListener('scroll', this.handleScroll);
    }

    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event): void {
      const popupHide = (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
      this.isUserScrolling = window.scrollY > 500 && !this.isPopupOpened && !popupHide;
      if (popupHide) {
        this.isPopupOpened = false;
      }
      event.preventDefault()
    }

    togglePopup(): void {
      this.isPopupOpened = !this.isPopupOpened;
      this.togglePopupButton();
    }

    togglePopupButton(): void {
      this.isUserScrolling = !this.isUserScrolling;
    }
  }

