
import { Component, Vue } from 'vue-property-decorator';
import MainCarouselComponent from './components/main-info.component.vue'
import MainFormComponent from './components/main-form.component.vue'
import MainInfoComponent from "@/views/main-screen/components/main-info.component.vue";

@Component({
  components: {
    MainCarouselComponent,
    MainFormComponent,
    MainInfoComponent
  }
})
export default class MainScreen extends Vue {
}
