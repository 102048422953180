

import {Component, Vue} from 'vue-property-decorator';
import ContactsBlockComponent from "@/shared/components/contacts-block.vue";

@Component({
  name: 'main-carousel',
  components: {
    ContactsBlockComponent
  }
})
export default class MainInfoComponent extends Vue {

}
