

  import {Component, Vue} from "vue-property-decorator";

  @Component({
    name: 'list-item'
  })

  export default class HeaderListItem extends Vue {

    headerItems = [
      {
        href: '#services',
        name: 'header.services'
      },
      {
        href: '#prices',
        name: 'header.prices'
      },
      {
        href: '#diagnostics',
        name: 'header.diagnostics'
      },
      {
        href: '#contacts',
        name: 'header.contact'
      }
    ];

    toggleMenu() {
      this.$emit('closeMenu');
    }
  }

