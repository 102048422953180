
  import {Component, Vue} from 'vue-property-decorator';
  import MainScreen from '@/views/main-screen/main-screen.component.vue';
  import HeaderDesktop from '@/shared/components/header/Header-desktop.vue';
  import HeaderMobileComponent from '@/shared/components/header/Header-mobile.vue';
  import BrandsComponent from "@/views/brands.vue";
  import CooperationComponent from "@/views/cooperation.vue"
  import ServicesComponent from "@/views/services.vue"
  import PricesComponent from "@/views/prices.vue"
  import MapComponent from "@/views/map.vue"
  import DiagnosticComponent from "@/views/diagnostic.vue";
  import FooterComponent from "@/views/footer.vue";
  import CookieComponent from "@/views/cookie.vue";
  import PopUpComponent from "@/views/pop-up.vue";

  @Component({
    components: {
      MainScreen,
      HeaderDesktop,
      HeaderMobileComponent,
      BrandsComponent,
      CooperationComponent,
      ServicesComponent,
      PricesComponent,
      MapComponent,
      DiagnosticComponent,
      FooterComponent,
      CookieComponent,
      PopUpComponent
    }
  })
  export default class App extends Vue {
    showNavigation = false;
  }
