<template>
  <section class="map"
           id="map">
    <div class="map__title">
      <h4 class="map__title-text">{{$t('map.title')}}</h4>
      <p class="map__subtitle">{{$t('map.subtitle')}}</p>
    </div>
    <div class="map__block">
      <img class="map__kyiv" src="../assets/images/full-map.svg" alt="map">
      <span class="map__district map__district_podol">{{$t('map.district-podol')}}</span>
      <span class="map__district map__district_obolon">{{$t('map.district-obolon')}}</span>
      <span class="map__district map__district_svyatoshin">{{$t('map.district-svyatoshin')}}</span>
      <span class="map__district map__district_shevchenko">{{$t('map.district-shevchenko')}}</span>
      <span class="map__district map__district_soloma">{{$t('map.district-soloma')}}</span>
      <span class="map__district map__district_goloseevo">{{$t('map.district-goloseevo')}}</span>
      <span class="map__district map__district_pechersk">{{$t('map.district-pechersk')}}</span>
      <span class="map__district map__district_darnitsa">{{$t('map.district-darnitsa')}}</span>
      <span class="map__district map__district_dnipro">{{$t('map.district-dnipro')}}</span>
      <span class="map__district map__district_desna">{{$t('map.district-desna')}}</span>
    </div>
  </section>
</template>

<script>
  import {Component, Vue} from "vue-property-decorator";

  @Component({
    name: 'map-component'
  })
  export default class MapComponent extends Vue {

  }
</script>

<style lang="scss" scoped>
  @import "../assets/styles/index";

  .map {
    display: flex;
    padding: 50px 16px 0;
    background: url("../assets/images/map-background.svg") center no-repeat;
    position: relative;
    width: 100%;
    flex-direction: column;

    @include breakpoint($media-small-desktop) {
      background: url("../assets/images/map-background.svg") left no-repeat;
      padding: 50px 16px 0;
      flex-direction: row;
    }

    @include breakpoint($media-desktop) {
      padding: 100px 5% 0 5%;
    }

    @include breakpoint($media-desktop-large) {
      padding: 100px 10% 0 10%;
    }

    &__kyiv {
      height: 100%;
    }

    &__title {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include breakpoint($media-small-desktop) {
        width: 40%;
      }
    }

    &__title-text {
      @include title();
    }

    &__subtitle {
      @include Text-Normal-light(20px, 32px, $color-gray-dark);
      margin: 15px 0 25px;

      @include breakpoint($media-tablet-only) {
        width: 70%;
      }

      @include breakpoint($media-small-desktop) {
        @include Text-Normal-light(24px, 38px, $color-gray-dark);
      }

      @include breakpoint($media-desktop-large) {
        @include Text-Normal-light(30px, 43px, $color-gray-dark);
        letter-spacing: 0.1em;
        margin: 55px 0 0;
      }
    }

    &__block {
      display: block;
      width: 100%;
      position: relative;

      @include breakpoint($media-small-desktop) {
        width: 60%;
      }
    }

    &__district {
      @include Text-Normal(12px, 22px, $color-gray-dark);
      text-transform: uppercase;
      display: block;
      position: absolute;
      visibility: hidden;

      @include breakpoint($media-desktop) {
        width: 60%;
      }

      &_podol {
        top: 62px;
        left: 56px;
      }

      &_obolon {
        left: 200px;
        color: $color-white;
        top: 62px;
      }

      &_svyatoshin {
        top: 100px;
      }

      &_shevchenko {
        top: 222px;
        left: 93px;
      }

      &_soloma {
        top: 265px;
        left: 107px;
      }

      &_goloseevo {
        top: 303px;
        left: 123px;
      }

      &_pechersk {
        top: 333px;
        left: 149px;
      }

      &_darnitsa {

      }

      &_dnipro {

      }

      &_desna {

      }

    }
  }
</style>
