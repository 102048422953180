

  import {Component, Vue} from "vue-property-decorator";
  import ContactsBlockComponent from "@/shared/components/contacts-block.vue";
  import PhoneInputComponent from "@/shared/components/phone-input.vue";

  @Component({
    name: 'footer-component',
    components: {
      ContactsBlockComponent,
      PhoneInputComponent
    }
  })
  export default class FooterComponent extends Vue {
  }
