

  import {Component, Vue} from "vue-property-decorator";
  import {helpers, maxLength, minLength, required} from "vuelidate/lib/validators";
  import emailjs from "emailjs-com";
  import {config} from "@/config";

  @Component({
    name: 'phone-input',
    validations() {
      return {
        form: {
          phone: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10),
            alpha: helpers.regex('alpha', /\d{10}/),
          }
        }
      }
    }
  })

  export default class PhoneInputComponent extends Vue {
    form = {
      phone: ''
    }

    submitStatus = '';

    sendFooterForm(e) {
      this.$v.form.$touch();
      if (!this.$v.$invalid) {
        this.submitStatus = 'form.status-pending';
        this.sendToEmail(e);
      } else {
        this.submitStatus = 'form.status-error'
      }
    }

    sendToEmail(e) {
      emailjs.init(config.emailJsUser)
      emailjs.sendForm(config.emailJsService, config.phoneJsTemplate, e.target)
        .then(() => {
          setTimeout(() => {
            this.submitStatus = 'form.status-ok';
            this.resetForm();
            this.$v.$reset();
            window.dataLayer?.push({
              event: "sendphone"
            });
          }, 500)
        }, () => {
          this.submitStatus = 'form.status-failed';
        });
    }

    resetForm() {
      this.form.phone = '';
    }
  }

