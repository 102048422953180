
import {Component, Vue} from "vue-property-decorator";

  @Component({
    name: 'header-lang-switcher'
  })

  export default class HeaderLangsSwitcher extends Vue {

    isActive = true;

    onChangeRu(event) {
      this.$i18n.locale = event;
      this.isActive = !this.isActive;
      window.dataLayer?.push({
        event: "langru"
      });
    }

    onChangeUk(event) {
      this.$i18n.locale = event;
      this.isActive = !this.isActive;
      window.dataLayer?.push({
        event: "languk"
      });
    }
  }

