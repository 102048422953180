
  import {Component, Vue} from "vue-property-decorator";
  import imgOne from "./../assets/images/ariston.png"
  import imgTwo from "./../assets/images/atlantic.png"
  import imgThree from "./../assets/images/electrolux.png"
  import imgFour from "./../assets/images/garanterm.png"
  import imgFive from "./../assets/images/gorenje.png"
  import imgSix from "./../assets/images/thermex.png"

  @Component({
    name: 'brands'
  })

  export default class BrandsComponent extends Vue {
    brands = [
      {
        img: imgOne,
        alt: 'ariston'
      },
      {
        img: imgTwo,
        alt: 'atlantic'
      },
      {
        img: imgThree,
        alt: 'electrolux'
      },
      {
        img: imgFour,
        alt: 'garanterm'
      },
      {
        img: imgFive,
        alt: 'gorenje'
      },
      {
        img: imgSix,
        alt: 'thermex'
      },
    ]
  }

