<template>
  <div class="header-mobile">
    <HeaderLogo></HeaderLogo>
    <HeaderLangsSwitcher></HeaderLangsSwitcher>
    <HeaderListItem @closeMenu="toggleMenu"></HeaderListItem>
  </div>
</template>

<script>

  import HeaderListItem from './shared/Header-list-item.vue'
  import HeaderLangsSwitcher from './shared/Header-langs-switcher.vue'
  import HeaderLogo from './shared/Header-logo.vue'
  import {Component, Vue} from "vue-property-decorator";

  @Component({
    name: 'header-mobile',
    components: {
      HeaderListItem,
      HeaderLangsSwitcher,
      HeaderLogo
    }
  })

  export default class HeaderMobileComponent extends Vue {
    toggleMenu() {
      this.$emit('closeMenu');
    }
  }

</script>

<style scoped lang="scss">
  @import "../../../assets/styles/index";
</style>
