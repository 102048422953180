
  import {Component, Vue} from "vue-property-decorator";

  @Component ({
    name: 'contact-block',
    props: {
      titleColor: String,
      iconsColor: String
    }
  })
  export default class ContactsBlockComponent extends Vue {
  }
