
  // import VueRecaptcha from 'vue-recaptcha';
  import {
    helpers,
    maxLength,
    minLength,
    required
  } from 'vuelidate/lib/validators';
  import emailjs from 'emailjs-com';
  import {Component, Vue} from 'vue-property-decorator';
  import {config} from '@/config'

  @Component({
    name: 'main-form',
    // components: {
    //   'vue-recaptcha': VueRecaptcha
    // },
    validations() {
      return {
        form: {
          name: {
            required
          },
          phone: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10),
            alpha: helpers.regex('alpha', /\d{10}/),
          },
          text: {
            required
          }
        }
      }
    },
  })

  export default class MainFormComponent extends Vue {

    siteKey = config.recaptchaSiteKey;
    submitStatus = '';

    form = {
      name: '',
      text: '',
      phone: '',
      recaptcha: null,
    };

    sendFormData(e) {
      this.$v.form.$touch();
      // this.$refs.invisibleRecaptcha.execute();
      if (!this.$v.$invalid) {
        this.submitStatus = 'form.status-pending';
        this.sendToEmail(e);
      } else {
        this.submitStatus = 'form.status-error'
      }
    }

    sendToEmail(e) {
      emailjs.sendForm(config.emailJsService, config.emailJsTemplate, e.target, config.emailJsUser)
        .then(() => {
          setTimeout(() => {
            this.submitStatus = 'form.status-ok';
            this.resetForm();
            this.$v.$reset();
            window.dataLayer?.push({
              event: "sendform"
            });
          }, 500)
        }, () => {
          this.submitStatus = 'form.status-failed';
        });
    }

    resetForm() {
      this.form.phone = '';
      this.form.name = '';
      this.form.text = '';
    }

    // onVerify(response) {
    //   this.form.recaptcha = response;
    //   console.log('Verify: ' + response);
    // }
    //
    // onCaptchaExpired() {
    //   //this.$refs.invisibleRecaptcha.reset();
    //   console.log('Expired');
    // }
  }
