
  import {
    Component,
    Vue
  } from "vue-property-decorator";
  import diagnostic from '../assets/images/diagnostic.svg'
  import repair from '../assets/images/repair.svg'
  import plug from '../assets/images/plug.svg'
  import cleaning from '../assets/images/cleaning.svg'
  import installation from '../assets/images/installation.svg'
  import toilet from '../assets/icons/toilet.svg'
  import shower from '../assets/icons/shower.svg'
  import bath from '../assets/icons/bath.svg'
  import faucet from '../assets/icons/faucet.svg'
  import pipe from '../assets/icons/plumbing.svg'
  import waste from '../assets/icons/waste.svg'
  import heater from '../assets/icons/heater.svg'
  import fiting from '../assets/icons/pipeline.svg'
  import reductor from '../assets/icons/pressure-gauge.svg'
  import sifon from '../assets/icons/siphon.svg'

  @Component({
    name: 'services'
  })

  export default class ServicesComponent extends Vue {
    servicesBoiler = [
      {
        icon: diagnostic,
        description: 'services.diagnostic'
      },
      {
        icon: repair,
        description: 'services.repair'
      },
      {
        icon: plug,
        description: 'services.plug'
      },
      {
        icon: cleaning,
        description: 'services.cleaning'
      },
      {
        icon: installation,
        description: 'services.installation'
      }
    ]

    servicesWaste = [
      {
        icon: toilet,
        description: 'services.waste-1'
      },
      {
        icon: shower,
        description: 'services.waste-2'
      },
      {
        icon: bath,
        description: 'services.waste-3'
      },
      {
        icon: faucet,
        description: 'services.waste-4'
      },
      {
        icon: pipe,
        description: 'services.waste-5'
      },
      {
        icon: waste,
        description: 'services.waste-6'
      },
      {
        icon: heater,
        description: 'services.waste-7'
      },
      {
        icon: sifon,
        description: 'services.waste-8'
      },
      {
        icon: fiting,
        description: 'services.waste-9'
      },
      {
        icon: reductor,
        description: 'services.waste-10'
      }
    ]
  }

