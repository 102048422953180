

  import {Component, Vue} from "vue-property-decorator";

  @Component({
    name: 'cooperation'
  })

  export default class CooperationComponent extends Vue {
    steps = [
      {
        title: 'cooperation.step-title-one',
        subtitle: 'cooperation.step-subtitle-one'
      },
      {
        title: 'cooperation.step-title-two',
        subtitle: 'cooperation.step-subtitle-two'
      },
      {
        title: 'cooperation.step-title-three',
        subtitle: 'cooperation.step-subtitle-three'
      }
    ]
  }

