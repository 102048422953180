

  import {Component, Vue} from "vue-property-decorator";

  @Component({

  })
  export default class PricesComponent extends Vue {
    prices = [
      {
        item: 'prices.item-one',
        price: 'prices.price-one'
      },
      {
        item: 'prices.item-two',
        price: 'prices.price-two'
      },
      {
        item: 'prices.item-three',
        price: 'prices.price-three'
      },
      {
        item: 'prices.item-four',
        price: 'prices.price-four'
      },
      {
        item: 'prices.item-five',
        price: 'prices.price-five'
      }
    ]
  }

