import Vue from 'vue';
import App from './App.vue';
import i18n from './i18n';
import Vuelidate from 'vuelidate';
import VueScrollactive from 'vue-scrollactive';

// Import Material
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';

Vue.use(VueMaterial);
Vue.use(Vuelidate);
Vue.use(VueScrollactive);
Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueScrollactive);

new Vue({
  i18n,
  render: h => h(App)
}).$mount('#app');
