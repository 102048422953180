<template>
  <div v-if="isShown"
       class="cookie">
    <p class="cookie__text">
      {{$t('cookie')}}
    </p>
    <md-button class="cookie__button"
               @click="close()">
      {{$t('cookie-button')}}
    </md-button>
  </div>
</template>

<script>

  import cookies from 'js-cookie';
  import {Component, Vue} from "vue-property-decorator";

  @Component({
    name: 'cookies',
  })

  export default class CookieComponent extends Vue {

    isShown = cookies.get('useCookies') !== "true";

    setCookie() {
      cookies.set('useCookies', 'true', {
        expires: 365,
      });
    }

    close() {
      this.isShown = !this.isShown;
      this.setCookie();
    }
  }

</script>

<style lang="scss"
       scoped>
  @import "../assets/styles/index";

  .cookie {
    display: flex;
    padding: 15px;
    background-color: black;
    opacity: .9;
    width: 100%;

    &__text {
      @include Text-Normal(12px, 14px, $color-white);
      display: flex;
      align-items: center;
    }

    &__button {
      display: block;
      background-color: $color-rose;
      min-width: 120px;
    }
  }

</style>
