
  import {Component, Vue} from "vue-property-decorator";

  @Component({
    name: 'diagnostic',
    components: {
    }
  })
  export default class DiagnosticComponent extends Vue {
    items = [
      {
        title: 'diagnostic.item-one-title',
        description: 'diagnostic.item-one-desc'
      },
      {
        title: 'diagnostic.item-two-title',
        description: 'diagnostic.item-two-desc'
      },
      {
        title: 'diagnostic.item-three-title',
        description: 'diagnostic.item-three-desc'
      },
      {
        title: 'diagnostic.item-four-title',
        description: 'diagnostic.item-four-desc'
      },
      {
        title: 'diagnostic.item-five-title',
        description: 'diagnostic.item-five-desc'
      },
      {
        title: 'diagnostic.item-six-title',
        description: 'diagnostic.item-six-desc'
      }
    ]
  }

